import axios from 'axios';
import moment from 'moment';
import Pagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import AlertadeViaje from '../components/AlertadeViaje';
import Breadcrumbs from '../components/Breadcrumbs ';
import { useUsuarios } from '../context/UsuariosContext';
import Saldoexitoso from '../Images/Saldoexitoso.png';
import Saldopendiente from '../Images/Saldopendiente.png';


const Reservas = () => {

    const { usuarios } = useUsuarios([]);
    const options = { headers: { authorization: "Bearer " + usuarios.token }, };
    const esAdminOSuperAdmin = usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin';
    const [tipoDocumento, setTipoDocumento] = useState("COTIZACIÓN");
    const [reservas, setReservas] = useState([])
    const [fechaReserva, setFechaReserva] = useState('')
    const [clientes, setClientes] = useState('')
    const [fechaSalida, setFechaSalida] = useState('')
    const [fechaLlegada, setFechaLlegada] = useState('')
    const [npasajeros, setNPasajeros] = useState('')

    const [destinos, setDestinos] = useState([]);
    const [paquetesturisticos, setPaquetesturisticos] = useState([]);
    const [selectedDestino, setSelectedDestino] = useState("");
    const [selectedPaquete, setSelectedPaquete] = useState("");
    const [destinoNombres, setDestinoNombres] = useState({});
    const [paqueteNombres, setPaqueteNombres] = useState({});
    const [destinoSeleccionado, setDestinoSeleccionado] = useState('');
    const [paqueteSeleccionado, setPaqueteSeleccionado] = useState('');
    const [costosPaqueteTuristico, setCostosPaqueteTuristico] = useState([]);
    const [totalAportes, setTotalAportes] = useState(0);
    const [cantidades, setCantidades] = useState(Array.from({ length: costosPaqueteTuristico.length }, () => 0));

    const [costosReserva, setCostosReserva] = useState([
        {
            nombredelcostoReserva: '',
            cantidadcostoReserva: '',
            anocostoReserva: '',
            preciodelcostoReserva: '',
            subtotalcostoReserva: '',
            isAdding: true,
        },
    ]);

    const [pasajeros, setPasajeros] = useState([
        {
            documentoPasajero: '',
            nombreCompletoPasajero: '',
            acomodacionPasajero: '',
            grupoAcomodacion: '',
            isAdding: true,
        },
    ]);

    const [pagosOaportes, setPagosOaportes] = useState([
        {
            fechaAporte: '',
            mediodepagoAporte: '',
            valorAporte: '',
            isAdding: true,
        },
    ]);

    const [totalCosto, setTotalCosto] = useState(0);
    const [totalpagosOaportes, setTotalpagosOaportes] = useState(0);
    const [totalDescuento, setTotalDescuento] = useState(0);
    const [totalReserva, setTotalReserva] = useState(0);
    const [key, setKey] = useState('reserva'); // Estado para manejar las pestañas

    //------------------------------------------------------------------------------------------
    const [errorFechaSalida, setErrorFechaSalida] = useState('');
    const [errorFechaLlegada, setErrorFechaLlegada] = useState('');
    //------------------------------------------------------------------------------------------
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState('');

    //------------------------------------------------------------------------------------------
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredReservas, setFilteredReservas] = useState([]); // Aquí almacenarás los Paquetes filtrados
    //----------------------------------------------------------------------------------------

    const [edit, setEdit] = useState(false);
    const [isModalOpenFormReservas, setIsModalOpenFormReservas] = useState(false)

    //-----------------------------------------------------------------------------------------
    useEffect(() => {
        getData();
        setKey("reserva");
        obtenerDestinos();
        obtenerPaquetesturisticos();
        if (isModalOpenFormReservas) {
            setKey('reserva');  // Cuando el modal se abre, asegurarse de que se seleccione "reserva"
        }
        setFechaReserva(moment().format('YYYY-MM-DD'));
        // eslint-disable-next-line
    }, [isModalOpenFormReservas]);

    //-----------------------------------------------------------------------------------------
    const cleanData = () => {
        setTipoDocumento('')
        setReservas([])
        setFechaReserva('')
        setClientes('')
        setDocumentoCliente('')
        setNombreCompleto('')
        setFechaSalida('')
        setFechaLlegada('')
        setNPasajeros('')
        setDestinos([]);
        setPaquetesturisticos([]);
        setSelectedPaquete('');
        setValidacionDocumento('')

        setEdit(false);

        setSelectedDestino('');
        setDestinoSeleccionado('');
        obtenerDestinos('');
        /* setDestinoNombres(''); */
        handleDestinoChange('');
        handlePaqueteChange('');

        setCostosPaqueteTuristico('');
        setCostosReserva(
            [
                {
                    nombredelcostoReserva: '',
                    cantidadcostoReserva: '',
                    anocostoReserva: '',
                    preciodelcostoReserva: '',
                    subtotalcostoReserva: '',
                    isAdding: false,
                }
            ]
        )
        setCantidades('');
        setPasajeros(
            [
                {
                    documentoPasajero: '',
                    nombreCompletoPasajero: '',
                    acomodacionPasajero: 'Acomodación',
                    grupoAcomodacion: '',
                    isAdding: false,
                }
            ]
        )
        setPagosOaportes(
            [
                {
                    fechaAporte: '',
                    mediodepagoAporte: '',
                    valorAporte: '',
                    isAdding: false,
                }
            ]
        )

        setTotalDescuento('');

    }
    //-----------------------------------------------------------------------------------------
    const getData = async (pageCurrent) => {
        const { data } = await axios.get(`/api/reservas/list?page=${pageCurrent}`, options);
        setReservas(data.reservas.docs);
        setFilteredReservas(data.reservas.docs);
        setPage(data.reservas.page);
        setTotalPages(data.reservas.totalPages);
    };
    //-----------------------------------------------------------------------------------------
    const onChangePage = (page) => {
        getData(page);
    };
    //-----------------------------------------------------------------------------------------
    const saveReserva = async () => {
        try {
            if (!selectedDestino || !selectedPaquete) {
                // Puedes mostrar un mensaje de error o tomar alguna acción aquí
                return;
            }

            // Verifica que `usuarios` y `usuarios.id` estén disponibles
            if (!usuarios || !usuarios.id) {
                console.error('El ID del usuario es null o undefined');
                return;
            }

            // Crear el objeto de nueva reserva
            const newReserva = {
                tipoDocumento,
                fechaReserva,
                clientes, // Agregamos el clienteId al array de clientes
                fechaSalida,
                fechaLlegada,
                npasajeros,
                destinos: selectedDestino,
                paquetesturisticos: selectedPaquete,
                usuarios: [usuarios.id],  // Añadir el ID del usuario a la lista de usuarios
                costosReserva: costosReserva.map((detalleCosto) => ({
                    nombredelcostoReserva: detalleCosto.nombredelcostoReserva,
                    cantidadcostoReserva: detalleCosto.cantidadcostoReserva,
                    anocostoReserva: detalleCosto.anocostoReserva,
                    preciodelcostoReserva: detalleCosto.preciodelcostoReserva,
                    subtotalcostoReserva: detalleCosto.subtotalcostoReserva,
                })),

                pasajeros: pasajeros.map((detalle) => ({
                    documentoPasajero: detalle.documentoPasajero,
                    nombreCompletoPasajero: detalle.nombreCompletoPasajero,
                    acomodacionPasajero: detalle.acomodacionPasajero,
                    grupoAcomodacion: detalle.grupoAcomodacion,
                })),

                pagosOaportes: pagosOaportes.map((detallePagosyAportes) => ({
                    fechaAporte: detallePagosyAportes.fechaAporte,
                    mediodepagoAporte: detallePagosyAportes.mediodepagoAporte.toUpperCase(),
                    valorAporte: detallePagosyAportes.valorAporte,
                })),

                totalCosto,
                totalpagosOaportes,
                totalReserva,
                totalDescuento,
            };

            // Enviar la solicitud POST para guardar la reserva
            await axios.post('/api/reservas/add/', newReserva, options);
            cleanData();
            getData();
            closeModal();

            // SweetAlert2 para mostrar éxito
            Swal.fire({
                icon: 'success',
                title: 'La Reserva se guardó exitosamente',
                showConfirmButton: false,
                timer: 1500
            });

            setTimeout(() => {
                getData();
            }, 1000); // Espera 1 segundo antes de recargar
        } catch (error) {
            if (!error.response.data.ok) {
                return alert(error.response.data.message);
            }
            console.log('error en saveReserva', error.message);
        }
    };


    //----------------------------------------------------------------------------------------------------
    const updateReserva = async () => {
        try {
            const id = localStorage.getItem('id');
            const newReserva = {
                tipoDocumento,
                fechaReserva,
                clientes,
                fechaSalida,
                fechaLlegada,
                npasajeros,
                destinos: selectedDestino,
                paquetesturisticos: selectedPaquete,
                costosReserva,
                pasajeros,
                pagosOaportes,
                totalCosto,
                totalpagosOaportes,
                totalReserva,
                totalDescuento,
            };

            const response = await axios.put('/api/reservas/' + id, newReserva, options);

            setSelectedDestino(response.data.destinos);
            setSelectedPaquete(response.data.paquetesturisticos);

            cleanData();
            closeModal();
            getData();

            Swal.fire({
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (error) {
            console.error('Error en updateReserva', error);

            // Captura el mensaje de error si el servidor devuelve un estado 400
            if (error.response && error.response.status === 400) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            } else {
                // Muestra un mensaje de error genérico si no es un 400 específico
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error en la solicitud',
                });
            }
        }
    };


    //----------------------------------------------------------------------------------------------------
    const editData = async (item) => {
        setEdit(true);

        setTipoDocumento(item.tipoDocumento || '');
        const formattedFechaReserva = item.fechaReserva ? item.fechaReserva.slice(0, 10) : '';
        setFechaReserva(formattedFechaReserva); // Asegura que la fecha esté en el formato correcto

        setClientes(item.clientes || []);

        // Asegúrate de que documentoClienteMapeo esté actualizado antes de esta línea
        const docCliente = documentoClienteMapeo[item.clientes] || '';
        setDocumentoCliente(docCliente);

        // No es necesario volver a setear el mapeo completo aquí
        // setDocumentoClienteMapeo(item.clientes); <-- Esta línea parece innecesaria

        setNombreCompleto(nombreCompleto);
        setFechaSalida(item.fechaSalida || '');
        setFechaLlegada(item.fechaLlegada || '');
        setNPasajeros(item.npasajeros);

        setSelectedDestino(item.destinos);
        await handleDestinoChange(item.destinos);
        setSelectedPaquete(item.paquetesturisticos);
        handlePaqueteChange(item.paquetesturisticos);

        setCostosPaqueteTuristico(item.costosPaqueteTuristico || []);
        const nuevasCantidades = item.costosReserva.map((detalle) => detalle.cantidadcostoReserva);
        setCantidades(nuevasCantidades);
        setCostosReserva(item.costosReserva || []);

        setTotalCosto(item.totalCosto);
        setPasajeros(item.pasajeros || []);
        setPagosOaportes(item.pagosOaportes || []);
        setTotalpagosOaportes(item.totalpagosOaportes);
        setTotalReserva(item.totalReserva);
        setTotalDescuento(item.totalDescuento);

        if (item.pagosOaportes && item.pagosOaportes.length > 0) {
            const fechaAporte = item.pagosOaportes[0].fechaAporte || '';
            const formattedFechaAporte = fechaAporte.slice(0, 10);
            console.log('Fecha de Aporte:', formattedFechaAporte);
        }

        localStorage.setItem('id', item._id);
        setIsModalOpenFormReservas(true);
    };

    //----------------------------------------------------------------------------------------------------

    const formattedDateSalida = fechaSalida.slice(0, 10);
    const formattedDateLlegada = fechaLlegada.slice(0, 10);

    //-----------------------------------------------------------------------------------------
    const actions = async (e) => {
        e.preventDefault();
        edit ? updateReserva() : saveReserva();
    };
    //-----------------------------------------------------------------------------------------
    const closeModal = () => {
        setIsModalOpenFormReservas(false);

    };
    //-----------------------------------------------------------------------------------------

    const deleteReserva = async (id) => {
        try {
            Swal.fire({
                title: 'Esta seguro?',
                text: "No podras revertir el proceso!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { data } = await axios.delete('/api/reservas/' + id, options);
                    getData();
                    Swal.fire({
                        icon: 'success',
                        title: data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        } catch (error) {
            if (!error.response.data.ok) {
                return alert(error.response.data.message)
            }
            console.log('error en deleteReserva', error.message);
        }
    }
    //-----------------------------------------------------------------------------------------

    const obtenerDestinos = async () => {
        try {
            const response = await axios.get('/api/destinos/listall');
            console.log("Lista de destinos:", response);
            const destinosData = response.data.destinos;
            setDestinos(destinosData);

            // Crear el mapeo de destinoNombres
            const nombres = {};
            destinosData.forEach((destino) => {
                nombres[destino._id] = destino.nombreDestino;
            });
            setDestinoNombres(nombres);
        } catch (error) {
            console.error("Error al obtener la lista de destinos:", error);
        }
    };


    const handleDestinoChange = async (destinoId) => {
        try {
            if (!destinoId) {
                console.error('DestinoId es nulo o indefinido');
                return;
            }

            setSelectedDestino(destinoId);
            setDestinoSeleccionado(destinoId);

            const response = await axios.get(`/api/destinos/listid/${destinoId}`);
            const { paquetesPorDestino } = response.data || [];
            setPaquetesturisticos(paquetesPorDestino);
            setSelectedPaquete("");
            console.log("Paquetes turísticos por destino:", response);
        } catch (error) {
            console.error("Error al obtener la lista de paquetes turísticos por destino:", error);
            setPaquetesturisticos([]);
            console.error(error.message); // Agregamos un registro adicional del mensaje de error
        }
    };


    const obtenerPaquetesturisticos = async () => {
        try {
            const response = await axios.get('/api/paquetes/listall');
            console.log("Lista de paquetesturisticos:", response);
            const paquetesturisticos = response.data.paquetesturisticos;

            setPaquetesturisticos(paquetesturisticos);

            const nombresPaquete = {};
            paquetesturisticos.forEach((paquete) => {
                nombresPaquete[paquete._id] = paquete.nombrePaqueteTuristico;
            });

            setPaqueteNombres(nombresPaquete);
        } catch (error) {
            console.error("Error al obtener la lista de paquetesturisticos:", error);
        }
    };

    // eslint-disable-next-line no-unused-vars
    const [paqueteId, setPaqueteId] = useState(null);


    useEffect(() => {
        if (paqueteId) {
            obtenerCostosPaqueteTuristico(paqueteId);
        }
    }, [paqueteId]);

    const obtenerCostosPaqueteTuristico = async (paqueteId) => {
        try {
            // Verificar si paqueteId está presente
            if (!paqueteId) {
                console.error('No se ha seleccionado ningún paquete');
                return;
            }

            const response = await axios.get(`/api/paquetes/listid/${paqueteId}`);
            console.log("Costos del paquete turístico:", response);
            const costosPaqueteTuristico = response.data.paqueteturistico.costosPaqueteTuristico;

            setCostosPaqueteTuristico(costosPaqueteTuristico);
        } catch (error) {
            console.error("Error al obtener los costos del paquete turístico:", error);
        }
    };



    const handleCantidadChange = (e, index) => {
        const nuevasCantidades = [...cantidades]; // Crea una copia del arreglo de cantidades
        const nuevoValor = parseInt(e.target.value);
        nuevasCantidades[index] = isNaN(nuevoValor) ? 0 : nuevoValor; // Si e.target.value no es un número válido, se establece como 0
        setCantidades(nuevasCantidades); // Actualiza el estado de las cantidades

        // Actualiza costosReserva con los nuevos subtotales
        const nuevosCostosReserva = costosPaqueteTuristico.map((costo, i) => ({
            nombredelcostoReserva: costo.nombredelcostoPaqueteTuristico,
            anocostoReserva: costo.anocostoPaqueteTuristico,
            cantidadcostoReserva: nuevasCantidades[i],
            preciodelcostoReserva: costo.preciodelcostoPaqueteTuristico,
            subtotalcostoReserva: nuevasCantidades[i] * costo.preciodelcostoPaqueteTuristico
        }));

        setCostosReserva(nuevosCostosReserva);
    };



    const handlePaqueteChange = (paqueteId) => {
        setSelectedPaquete(paqueteId);
        setPaqueteSeleccionado(paqueteId);
        obtenerCostosPaqueteTuristico(paqueteId); // Llama a la función para obtener los costos del paquete turístico
    };



    //-----------------------------------------------------------------------------------------
    const handleAddRowPasajeros = () => {
        // Crea una nueva fila de detalle con valores iniciales
        const newDetail = {
            documentoPasajero: '',
            nombreCompletoPasajero: '',
            isAdding: true, // La nueva fila se marca como "agregando"
        };

        // Agrega la nueva fila al estado de pasajeros
        setPasajeros([...pasajeros, newDetail]);

    };

    const handleEliminarDetallePasajeros = (id) => {
        const updatedDetalles = pasajeros.filter((detalle) => detalle._id !== id);
        setPasajeros(updatedDetalles);
    };

    const handleAcomodacionChange = (index, value) => {
        const updatedPasajeros = [...pasajeros];
        updatedPasajeros[index].acomodacionPasajero = value;
        updatedPasajeros[index].grupoAcomodacion = ""; // Reinicia grupoAcomodacion al cambiar la acomodación
        setPasajeros(updatedPasajeros);
    };

    const handleGrupoAcomodacionChange = (index, value) => {
        const updatedPasajeros = [...pasajeros];
        updatedPasajeros[index].grupoAcomodacion = value;
        setPasajeros(updatedPasajeros);
    };

    const isGrupoValid = (grupo, acomodacion) => {
        const grupoCounts = {};

        // Recuento de grupos según la acomodación
        pasajeros.forEach((p) => {
            if (!grupoCounts[p.grupoAcomodacion]) {
                grupoCounts[p.grupoAcomodacion] = { SIMPLE: 0, DOBLE: 0, TRIPLE: 0, MULTIPLE: 0 };
            }
            grupoCounts[p.grupoAcomodacion][p.acomodacionPasajero] += 1;
        });

        // Validaciones de acuerdo a la acomodación
        if (acomodacion === 'SIMPLE') {
            // No debe estar repetido
            return !grupoCounts[grupo] || grupoCounts[grupo].SIMPLE === 0;
        } else if (acomodacion === 'DOBLE') {
            // Repetido dos veces, y no puede coincidir con acomodación SIMPLE
            return (!grupoCounts[grupo] || grupoCounts[grupo].SIMPLE === 0) && (grupoCounts[grupo]?.DOBLE || 0) < 2;
        } else if (acomodacion === 'TRIPLE') {
            // Repetido tres veces, y no puede coincidir con acomodación SIMPLE ni DOBLE
            return (!grupoCounts[grupo] || (grupoCounts[grupo].SIMPLE === 0 && grupoCounts[grupo].DOBLE === 0)) && (grupoCounts[grupo]?.TRIPLE || 0) < 3;
        } else if (acomodacion === 'MULTIPLE') {
            // Puede repetirse más de tres veces y no debe coincidir con SIMPLE, DOBLE o TRIPLE
            return !grupoCounts[grupo] || (grupoCounts[grupo].SIMPLE === 0 && grupoCounts[grupo].DOBLE === 0 && grupoCounts[grupo].TRIPLE === 0);
        }
        return false; // Si no coincide con ningún caso
    };

    //-----------------------------------------------------------------------------------------
    const handleAddRowPagosyAportes = () => {
        // Crea una nueva fila de detalle con valores iniciales
        const newDetailPagosyAportes = {

            fechaAporte: '',
            mediodepagoAporte: '',
            valorAporte: '',
            isAdding: true, // La nueva fila se marca como "agregando"
        };

        // Agrega la nueva fila al estado de pasajeros
        setPagosOaportes([...pagosOaportes, newDetailPagosyAportes]);

    };

    const handleEliminarDetallePagosyAportes = (id) => {
        const updatedDetallesPagosyAportes = pagosOaportes.filter((detallePagosyAportes) => detallePagosyAportes._id !== id);
        setPagosOaportes(updatedDetallesPagosyAportes);
    };


    const handleDetalleChange = (index, field, value) => {
        const updatedDetallesPagosyAportes = [...pagosOaportes];
        updatedDetallesPagosyAportes[index][field] = value;
        setPagosOaportes(updatedDetallesPagosyAportes);
    };

    //---------------------------------------------------------------------------------------------

    const [validacionDocumento, setValidacionDocumento] = useState(null);
    const [nombreCompleto, setNombreCompleto] = useState('');
    const [documentoCliente, setDocumentoCliente] = useState('');
    const [documentoClienteMapeo, setDocumentoClienteMapeo] = useState({});

    // Este código valida si el documento del Titular existe en la base de datos de Clientes
    const handleDocumentoClienteChange = async (e) => {
        const value = e.target.value;
        setDocumentoCliente(value);

        try {
            const response = await axios.get(`/api/clientes/verificar/${value}`);
            if (response.data.exists) {
                const { clienteId, documentoCliente, nombreCompleto } = response.data;
                setValidacionDocumento(true);
                setNombreCompleto(nombreCompleto);


                // Reemplazar la lista de clientes existentes con el nuevo cliente validado
                setClientes([{ _id: clienteId, documentoCliente: documentoCliente }]);
            } else {
                setValidacionDocumento(false);
                setNombreCompleto('');
            }
        } catch (error) {
            console.error('Error al validar el documento:', error);
            setValidacionDocumento(false);
            setNombreCompleto('');
        }
    };

    useEffect(() => {
        axios.get('/api/clientes/listall')
            .then((response) => {
                const clientesData = response.data.clientes;

                // Actualiza siempre la lista de clientes y el mapeo
                setClientes(clientesData);

                const mapeo = {};
                clientesData.forEach((cliente) => {
                    mapeo[cliente._id] = cliente.documentoCliente;
                });
                setDocumentoClienteMapeo(mapeo);
            })
            .catch((error) => {
                console.error('Error al obtener la lista de clientes:', error);
            });
    }, []);  // Ejecuta esto solo una vez al montar el componente


    useEffect(() => {
        // Verificar el documentoCliente cuando el modo de edición cambia Link true
        if (edit) {
            handleDocumentoClienteChange({ target: { value: documentoCliente } });
        }
    }, [edit, documentoCliente]);


    //-------------------------------------------------------------------------------------------


    const [validacionesDocumentosPasajeros, setValidacionesDocumentosPasajeros] = useState([]);
    const [nombresCompletosPasajeros, setNombresCompletosPasajeros] = useState([]);

    // Función para manejar el cambio en el documento del pasajero
    const handleDocumentoPasajeroChange = async (e, index) => {
        const value = e.target.value;

        try {
            const response = await axios.get(`/api/clientes/verificar/${value}`);
            const nuevasValidaciones = [...validacionesDocumentosPasajeros];
            const nuevosNombresCompletos = [...nombresCompletosPasajeros];

            if (response.data.exists) {
                const { nombreCompleto } = response.data;
                nuevasValidaciones[index] = true;
                nuevosNombresCompletos[index] = nombreCompleto;

                // Actualizar el arreglo de pasajeros con el nombre completo
                const updatedPasajeros = [...pasajeros];
                updatedPasajeros[index] = {
                    ...updatedPasajeros[index],
                    nombreCompletoPasajero: nombreCompleto
                };
                setPasajeros(updatedPasajeros);
            } else {
                nuevasValidaciones[index] = false;
                nuevosNombresCompletos[index] = '';

                // Limpiar el nombre completo en el arreglo de pasajeros si no existe el cliente
                const updatedPasajeros = [...pasajeros];
                updatedPasajeros[index] = {
                    ...updatedPasajeros[index],
                    nombreCompletoPasajero: ''
                };
                setPasajeros(updatedPasajeros);
            }

            setValidacionesDocumentosPasajeros(nuevasValidaciones);
            setNombresCompletosPasajeros(nuevosNombresCompletos);
        } catch (error) {
            console.error('Error al validar el documento del pasajero:', error);
            const nuevasValidaciones = [...validacionesDocumentosPasajeros];
            const nuevosNombresCompletos = [...nombresCompletosPasajeros];
            nuevasValidaciones[index] = false;
            nuevosNombresCompletos[index] = '';

            // Limpiar el nombre completo en el arreglo de pasajeros si ocurre un error
            const updatedPasajeros = [...pasajeros];
            updatedPasajeros[index] = {
                ...updatedPasajeros[index],
                nombreCompletoPasajero: ''
            };
            setPasajeros(updatedPasajeros);

            setValidacionesDocumentosPasajeros(nuevasValidaciones);
            setNombresCompletosPasajeros(nuevosNombresCompletos);
        }
    };

    useEffect(() => {
        if (edit) {
            // Iterar sobre cada pasajero para manejar la validación
            pasajeros.forEach((pasajero, index) => {

            });
        }
    }, [edit, pasajeros]);


    //--------------------------------------------------------------------------------------------

    const calcularTotalAportes = () => {
        // Suma los valores de valorAporte en pagosOaportes
        const totalAportes = pagosOaportes.reduce((total, detalle) => total + parseFloat(detalle.valorAporte || 0), 0);

        // Actualiza el estado con el total calculado
        setTotalAportes(totalAportes);
    };

    useEffect(() => {
        calcularTotalAportes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagosOaportes]);

    // Calcula la suma de los subtotales
    const sumaSubtotales = () => {
        let suma = 0;
        if (Array.isArray(costosPaqueteTuristico) && Array.isArray(cantidades)) {
            costosPaqueteTuristico.forEach((costo, index) => {
                const cantidad = cantidades[index];
                if (cantidad != null && cantidad !== '') {
                    suma += cantidad * costo.preciodelcostoPaqueteTuristico;
                }
            });
        }
        return suma;
    };

    // Define una función para calcular el total del paquete
    const calcularTotalPaquete = () => {
        const total = isNaN(sumaSubtotales()) ? 0 : sumaSubtotales();
        return total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
    };

    // Define una función para calcular el total de la reserva
    const calcularTotalReserva = () => {
        const total = (isNaN(sumaSubtotales()) ? 0 : sumaSubtotales()) - (totalDescuento || 0);
        return total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
    };

    const saldo = ((isNaN(sumaSubtotales()) ? 0 : sumaSubtotales()) - (totalDescuento || 0) - totalAportes);

    //----------------------------------------------------------------------------------------------




    // Función que determina el mensaje de la nota de pago
    const NotaDePago = () => {
        // Verificamos si todos los valores son 0 o null, y si es así no mostrar ningún mensaje
        const todosSonCeroONull =
            (totalAportes === 0 || totalAportes === null) &&
            (totalDescuento === 0 || totalDescuento === null) &&
            (totalReserva === 0 || totalReserva === null) &&
            (saldo === 0 || saldo === null);

        // Si todos los valores son 0 o null, no mostrar ningún mensaje
        if (todosSonCeroONull) {
            return null; // No mostrar mensaje si todos los valores son 0 o null
        }

        return (
            <>
                {/* Verificación del saldo para mostrar el mensaje de pendiente o al día */}
                <div className="col-12">
                    {saldo !== 0 && saldo !== null ? (
                        <div>
                            <img src={Saldopendiente} alt="" />
                            <label className="text-danger fw-bold">
                                El Usuario se encuentra pendiente de pagos en la reserva
                            </label>

                        </div>

                    ) : (
                        <div>
                            <img src={Saldoexitoso} alt="" />
                            <label className="text-success fw-bold">
                                El Usuario se encuentra al día con sus pagos en la reserva
                            </label>

                        </div>

                    )}
                </div>
            </>
        );
    };





    // -----------------------------------------------------------------------------------------

    const searchFields = [
        'fechaSalida',
        'documentoCliente',
        'destinoNombres',
        'paqueteNombres'
        // Agrega más campos aquí si es necesario
    ];

    // Función para eliminar tildes de un texto
    const removeAccents = (text) => {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const handleSearch = (e) => {
        const searchText = removeAccents(e.target.value.toUpperCase()); // Convertir texto de búsqueda a mayúsculas
        setSearchTerm(searchText);

        // Filtra las reservas en base a los campos de búsqueda definidos
        const filtered = reservas.filter((reserva) => {
            // Verifica si alguno de los campos de búsqueda coincide con el término de búsqueda
            return searchFields.some((field) => {
                if (field === 'destinoNombres') {
                    // Mapea los IDs de destino a los nombres de destino utilizando el objeto destinoNombres
                    const destinosNombres = reserva.destinos.map((destinoId) => destinoNombres[destinoId]);
                    // Verifica si alguno de los nombres de destino coincide con el término de búsqueda
                    return destinosNombres.some((destinoNombre) =>
                        removeAccents(destinoNombre).toUpperCase().includes(removeAccents(searchText).toUpperCase())
                    );
                } else if (field === 'paqueteNombres') {
                    // Busca el nombre del paquete turístico utilizando el ID de paquete
                    const paqueteNombre = paqueteNombres[reserva.paquetesturisticos];
                    return removeAccents(paqueteNombre).toUpperCase().includes(removeAccents(searchText).toUpperCase());
                } else {
                    // Para los demás campos, realiza la búsqueda directamente
                    return removeAccents(String(reserva[field])).toUpperCase().includes(removeAccents(searchText).toUpperCase());
                }
            });
        });

        setFilteredReservas(filtered);
    };

    //--------------------------------------------------------------------------------------------------------------------

    const handleTipoDocumentoChange = (e) => {
        setTipoDocumento(e.target.value);
    };


    //---------------------------------------------------------------------------------------------

    return (
        <div>
            <div className=" container" style={{ textAlign: 'left' }}>
                <Breadcrumbs />
            </div>
            {/* ------------------------------------------------------------------------------------------------*/}


            {/* ------------------------------------------------------------------------------------------------*/}
            {/* Inicio del formulario registro de Reservas*/}
            <div className='container-md mt-5'>
                <div className={`modal fade ${isModalOpenFormReservas ? 'show' : ''}`} id="exampleModalReservas" tabIndex="-1" aria-labelledby="exampleModalLabelReservas" aria-hidden={!isModalOpenFormReservas} style={{ display: isModalOpenFormReservas ? 'block' : 'none' }} >
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: "#008cba" }}>
                                <h5 className="modal-title text-white" id="exampleModalLabel">Ingreso de Reservas</h5>
                                <button type="button" className="btn-close bg-white" onClick={() => {
                                    cleanData(); // Limpia los campos del formulario
                                    getData(); // Carga los datos actualizados
                                    closeModal();
                                }} title='Cerrar Ventana' />
                            </div>
                            <div className="modal-body">
                                <div>
                                    <form id='reservasFrom' onSubmit={actions}>
                                        {/* ------------------------------------------------------------------------------------------------*/}
                                        {/* ------------------------------------------------------------------------------------------------*/}
                                        <div className='container-md mt-2'>
                                            <div className='d-flid col-12'>
                                                <ul className="nav nav-tabs mb-3 d-flex" style={{ flexWrap: 'nowrap' }}>
                                                    <li >
                                                        <Link
                                                            className={`nav-link ${key === 'reserva' ? 'active' : ''}`}
                                                            onClick={() => setKey('reserva')}
                                                        >
                                                            Reserva
                                                        </Link>
                                                    </li>
                                                    <li >
                                                        <Link
                                                            className={`nav-link ${key === 'pasajeros' ? 'active' : ''}`}
                                                            onClick={() => setKey('pasajeros')}
                                                        >
                                                            Pasajeros
                                                        </Link>
                                                    </li>
                                                    <li >
                                                        <Link
                                                            className={`nav-link ${key === 'pagosAportes' ? 'active' : ''}`}
                                                            onClick={() => setKey('pagosAportes')}
                                                        >
                                                            Aportes
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>


                                            <div className="tab-content">

                                                {/* --------------------------------------------------- INICIO SECCION DE RESERVAS -----------------------------------------------------*/}

                                                <div className={`tab-pane fade ${key === 'reserva' ? 'show active' : ''}`} id="reserva">
                                                    <div className="col-md-12 border border-2 table-responsive container container-flex">
                                                        <div className="row" >

                                                            <div className="col-md-2 mb-3">
                                                                <label htmlFor="tipoDocumento" className="form-label"><strong>Documento</strong></label>
                                                                <select
                                                                    id="tipoDocumento"
                                                                    className="form-select"
                                                                    value={tipoDocumento}
                                                                    onChange={handleTipoDocumentoChange}
                                                                >
                                                                    <option value="COTIZACIÓN">COTIZACIÓN</option>
                                                                    <option value="RESERVA">RESERVA</option>
                                                                </select>
                                                            </div>


                                                            <div className="col-md-2 mb-3">
                                                                <label htmlFor="validationCustom01" className="form-label"><strong>Fecha Reserva</strong></label><span className="text-danger red-asterisk">*</span>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id="fechaReserva"
                                                                    value={fechaReserva}
                                                                    onChange={(e) => setFechaReserva(e.target.value)}
                                                                    required
                                                                    disabled
                                                                />
                                                            </div>

                                                            <div className="col-md-2 mb-3">
                                                                <label htmlFor="validationCustom01" className="form-label"><strong>Documento cliente</strong></label><span className="text-danger red-asterisk">*</span>
                                                                <input
                                                                    type="text"
                                                                    className={`form-control ${validacionDocumento === true ? 'is-valid' : validacionDocumento === false ? 'is-invalid' : ''}`}
                                                                    id="documentoCliente"
                                                                    value={documentoCliente}
                                                                    onBlur={handleDocumentoClienteChange} // Cambio de evento a onBlur
                                                                    onChange={(e) => setDocumentoCliente(e.target.value)}
                                                                    required

                                                                />
                                                            </div>
                                                            <div className='col-md-6 mb-3'>
                                                                <label htmlFor="nombreCliente" className="form-label"><strong>Nombre del Cliente</strong></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    value={nombreCompleto}
                                                                    onChange={(e) => setNombreCompleto(e.target.value)} // Agregar el manejador de cambio
                                                                    readOnly
                                                                    disabled={!(usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'Asesor') || edit}
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* ------------------------------------------------------------------------------------------------*/}

                                                        <div className='row'>

                                                            <div className="col-md-6 mb-3">
                                                                <label htmlFor="fechaSalida" className="form-label"><strong>Fecha Salida</strong></label><span className="text-danger red-asterisk">*</span>
                                                                <input
                                                                    type="date"
                                                                    className={`form-control ${errorFechaSalida ? 'is-invalid' : ''}`}
                                                                    id="fechaSalida"
                                                                    value={formattedDateSalida}
                                                                    onChange={(e) => {
                                                                        const newFechaSalida = e.target.value;
                                                                        const fechaSalidaValidada = new Date(newFechaSalida);
                                                                        const fechaReservaDate = new Date(fechaReserva);

                                                                        if (fechaSalidaValidada < fechaReservaDate) {
                                                                            setErrorFechaSalida('La fecha no puede ser menor a la fecha de reserva.');
                                                                        } else if (fechaSalidaValidada.toDateString() === fechaReservaDate.toDateString()) {
                                                                            setErrorFechaSalida('La fecha no puede ser igual a la fecha de reserva.');
                                                                        } else {
                                                                            setFechaSalida(newFechaSalida);
                                                                            setErrorFechaSalida('');
                                                                        }
                                                                    }}
                                                                    required
                                                                />
                                                                {errorFechaSalida && <div className="invalid-feedback">{errorFechaSalida}</div>}
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label htmlFor="fechaLlegada" className="form-label"><strong>Fecha Regreso</strong> </label><span className="text-danger red-asterisk">*</span>
                                                                <input
                                                                    type="date"
                                                                    className={`form-control ${errorFechaLlegada ? 'is-invalid' : ''}`}
                                                                    id="fechaLlegada"
                                                                    value={formattedDateLlegada}
                                                                    onChange={(e) => {
                                                                        const newFechaLlegada = e.target.value;
                                                                        const fechaLlegadaValidada = new Date(newFechaLlegada);
                                                                        const fechaReservaDate = new Date(fechaReserva);
                                                                        const fechaSalidaDate = new Date(fechaSalida);

                                                                        if (fechaLlegadaValidada < fechaReservaDate) {
                                                                            setErrorFechaLlegada('La fecha de llegada no puede ser inferior a la fecha de reserva.');
                                                                        } else if (fechaLlegadaValidada.toDateString() === fechaSalidaDate.toDateString()) {
                                                                            setErrorFechaLlegada('La fecha de llegada no puede ser igual a la fecha de salida.');
                                                                        } else if (fechaLlegadaValidada.toDateString() === fechaReservaDate.toDateString()) {
                                                                            setErrorFechaLlegada('La fecha de llegada no puede ser igual a la fecha de reserva.');
                                                                        } else {
                                                                            setFechaLlegada(newFechaLlegada);
                                                                            setErrorFechaLlegada('');
                                                                        }
                                                                    }}
                                                                    required
                                                                />
                                                                {errorFechaLlegada && <div className="invalid-feedback">{errorFechaLlegada}</div>}
                                                            </div>

                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-md-2 mb-3">
                                                                <label htmlFor="paquete" className="form-label"><strong># Pasajeros: </strong></label>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    value={npasajeros}
                                                                    onChange={(e) => setNPasajeros(e.target.value)} />
                                                            </div>
                                                            <div className="col-md-4 mb-3">
                                                                <label htmlFor="destino" className="form-label"><strong>Destino</strong> </label>
                                                                <select
                                                                    className="form-select"
                                                                    value={edit ? selectedDestino : destinoSeleccionado}
                                                                    onChange={(e) => handleDestinoChange(e.target.value)}
                                                                >
                                                                    <option value="">Seleccione un destino</option>
                                                                    {Array.isArray(destinos) && destinos.length > 0 ? (
                                                                        destinos
                                                                            .filter((destino) => destino.estadoDestino === 'Activo')
                                                                            .map((destino) => (
                                                                                <option key={destino._id} value={destino._id}>
                                                                                    {destino.nombreDestino}
                                                                                </option>
                                                                            ))
                                                                    ) : (
                                                                        <option value="" disabled>
                                                                            Cargando destinos...
                                                                        </option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className="col-md-4 mb-3">
                                                                <label htmlFor="paqueteTuristico" className="form-label"><strong>Paquete Turístico</strong></label>
                                                                <select
                                                                    className="form-select"
                                                                    value={edit ? selectedPaquete : paqueteSeleccionado}
                                                                    onChange={(e) => handlePaqueteChange(e.target.value)}
                                                                >
                                                                    <option value="">Seleccione un paquete turístico</option>
                                                                    {Array.isArray(paquetesturisticos) && paquetesturisticos.length > 0 ? (
                                                                        paquetesturisticos.map((paquete) => (
                                                                            <option key={paquete._id} value={paquete._id}>
                                                                                {paquete.nombrePaqueteTuristico}
                                                                            </option>
                                                                        ))
                                                                    ) : (
                                                                        <option value="">
                                                                            No hay paquetes turísticos para este destino.
                                                                        </option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* Campo para ingresar el documento del cliente */}
                                                    </div>
                                                </div>

                                                {/* --------------------------------------------------- FIN SECCION DE RESERVAS -----------------------------------------------------*/}

                                                {/* --------------------------------------------------- INICIO SECCION PASAJEROS -----------------------------------------------------*/}

                                                <div className={`tab-pane fade ${key === 'pasajeros' ? 'show active' : ''}`} id="pasajeros">
                                                    <div className="col-md-12 border border-2 table-responsive container container-flex mt-3">
                                                        {/* Estructura de div para dispositivos móviles */}
                                                        <div className="d-md-none">
                                                            {/* Botón centrado */}
                                                            <div className="text-center mb-3 mt-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-success w-100"
                                                                    onClick={handleAddRowPasajeros} title='Agregar Pasajeros'
                                                                >
                                                                    Agregar Pasajeros
                                                                </button>
                                                            </div>
                                                            {/* Iteración de pasajeros */}
                                                            {pasajeros.map((pasajero, index) => (
                                                                <div key={`${pasajero._id}-${index}`} className="card mb-3">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Pasajero {index + 1}</h5>
                                                                        <div className="mb-3">
                                                                            <label htmlFor={`documentoPasajero-${index}`} className="form-label"><strong>Doc Pasajero</strong></label><span className="text-danger red-asterisk">*</span>
                                                                            <input
                                                                                type="text"
                                                                                className={`form-control ${validacionesDocumentosPasajeros[index] === true ? 'is-valid' : validacionesDocumentosPasajeros[index] === false ? 'is-invalid' : ''}`}
                                                                                id={`documentoPasajero-${index}`}
                                                                                value={pasajero.documentoPasajero}
                                                                                onChange={(e) => {
                                                                                    const updatedPasajeros = [...pasajeros];
                                                                                    updatedPasajeros[index].documentoPasajero = e.target.value;
                                                                                    setPasajeros(updatedPasajeros);
                                                                                    handleDocumentoPasajeroChange(e, index);
                                                                                }}
                                                                            />
                                                                            {validacionesDocumentosPasajeros[index] === false && (
                                                                                <div className="invalid-feedback">El documento del pasajero no existe.</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor={`nombreCompletoPasajero-${index}`} className="form-label"><strong>Nombre del Pasajero</strong> </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id={`nombreCompletoPasajero-${index}`}
                                                                                value={pasajero.nombreCompletoPasajero || ''}
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor={`acomodacion-${index}`} className="form-label"><strong>Acomodación</strong></label><span className="text-danger red-asterisk">*</span>
                                                                            <select
                                                                                className="form-select"
                                                                                value={pasajero.acomodacionPasajero || "Acomodación"} // Establecer "Acomodación" como valor predeterminado
                                                                                onChange={(e) => handleAcomodacionChange(index, e.target.value)}
                                                                            >
                                                                                <option value="Acomodación">Acomodación</option>
                                                                                <option value="SIMPLE">SIMPLE</option>
                                                                                <option value="DOBLE">DOBLE</option>
                                                                                <option value="TRIPLE">TRIPLE</option>
                                                                                <option value="MULTIPLE">MULTIPLE</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor={`grupoAcomodacion-${index}`} className="form-label"><strong>Grupo</strong></label><span className="text-danger red-asterisk">*</span>
                                                                            <select
                                                                                className="form-select"
                                                                                value={pasajero.grupoAcomodacion || "Acomodación"} // Establecer "Acomodación" como valor predeterminado
                                                                                onChange={(e) => {
                                                                                    const selectedGrupo = e.target.value;

                                                                                    if (pasajero.acomodacionPasajero === "Acomodación") {
                                                                                        Swal.fire({
                                                                                            icon: 'error',
                                                                                            title: 'Error',
                                                                                            text: 'No se puede seleccionar un grupo cuando la acomodación es "Acomodación".',
                                                                                            confirmButtonText: 'Aceptar'
                                                                                        });
                                                                                        return; // Evita el cambio si la acomodación es "Acomodación"
                                                                                    }

                                                                                    if (isGrupoValid(selectedGrupo, pasajero.acomodacionPasajero)) {
                                                                                        handleGrupoAcomodacionChange(index, selectedGrupo);
                                                                                    } else {
                                                                                        Swal.fire({
                                                                                            icon: 'error',
                                                                                            title: 'Error',
                                                                                            text: 'El grupo seleccionado no cumple con los requisitos de acomodación.',
                                                                                            confirmButtonText: 'Aceptar'
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                disabled={!["SIMPLE", "DOBLE", "TRIPLE", "MULTIPLE"].includes(pasajero.acomodacionPasajero)} // Habilitar solo si acomodación es SIMPLE, DOBLE, TRIPLE o MULTIPLE
                                                                            >
                                                                                <option value="">Grupo</option>
                                                                                {[...Array(20).keys()].map(i => (
                                                                                    <option key={i} value={i + 1}>{i + 1}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <>
                                                                            {esAdminOSuperAdmin && (
                                                                                <button
                                                                                    className="btn btn-danger w-100"
                                                                                    onClick={() => handleEliminarDetallePasajeros(pasajero._id)}
                                                                                    title='Eliminar'
                                                                                >
                                                                                    <i className="fa-solid fa-trash"></i> Eliminar Pasajero
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {/* Tabla para dispositivos de pantalla grande */}
                                                        <div className="d-none d-md-block">
                                                            {/* Botón en la esquina superior derecha */}
                                                            <div className="text-end mb-3 mt-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-success"
                                                                    onClick={handleAddRowPasajeros} title='Agregar Pasajeros'
                                                                >
                                                                    Agregar Pasajeros
                                                                </button>
                                                            </div>
                                                            {/* Tabla */}
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Doc Pasajero<span className="text-danger red-asterisk">*</span></th>
                                                                        <th>Nombre del Pasajero</th>
                                                                        <th>Acomodación<span className="text-danger red-asterisk">*</span></th>
                                                                        <th>Grupo<span className="text-danger red-asterisk">*</span></th>
                                                                        <th scope="col">Acción</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ maxHeight: '800px', overflowY: 'auto' }}>
                                                                    {pasajeros.map((pasajero, index) => (
                                                                        <tr key={`${pasajero._id}-${index}`}>
                                                                            <td>{index + 1}</td>

                                                                            {/* Campo para documento del pasajero */}
                                                                            <td className="col-2">
                                                                                <input
                                                                                    type="text"
                                                                                    className={`form-control ${validacionesDocumentosPasajeros[index] === true ? 'is-valid' : validacionesDocumentosPasajeros[index] === false ? 'is-invalid' : ''}`}
                                                                                    value={pasajero.documentoPasajero}
                                                                                    onChange={(e) => {
                                                                                        const updatedPasajeros = [...pasajeros];
                                                                                        updatedPasajeros[index].documentoPasajero = e.target.value;
                                                                                        setPasajeros(updatedPasajeros);
                                                                                        handleDocumentoPasajeroChange(e, index);
                                                                                    }}
                                                                                />
                                                                                {validacionesDocumentosPasajeros[index] === false && (
                                                                                    <div className="invalid-feedback">El documento del pasajero no existe.</div>
                                                                                )}
                                                                            </td>

                                                                            {/* Campo para nombre completo del pasajero */}
                                                                            <td className="col-6">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={pasajero.nombreCompletoPasajero || ''}
                                                                                    readOnly
                                                                                />
                                                                            </td>

                                                                            {/* Select para acomodación */}
                                                                            <td className="col-2">
                                                                                <select
                                                                                    className="form-select"
                                                                                    value={pasajero.acomodacionPasajero || "Acomodación"} // Establecer "Acomodación" como valor predeterminado
                                                                                    onChange={(e) => handleAcomodacionChange(index, e.target.value)}
                                                                                >
                                                                                    <option value="Acomodación">Acomodación</option>
                                                                                    <option value="SIMPLE">SIMPLE</option>
                                                                                    <option value="DOBLE">DOBLE</option>
                                                                                    <option value="TRIPLE">TRIPLE</option>
                                                                                    <option value="MULTIPLE">MULTIPLE</option>
                                                                                </select>
                                                                            </td>

                                                                            {/* Select para grupo de acomodación */}
                                                                            <td className="col-2">
                                                                                <select
                                                                                    className="form-select"
                                                                                    value={pasajero.grupoAcomodacion || "Acomodación"} // Establecer "Acomodación" como valor predeterminado
                                                                                    onChange={(e) => {
                                                                                        const selectedGrupo = e.target.value;

                                                                                        if (pasajero.acomodacionPasajero === "Acomodación") {
                                                                                            Swal.fire({
                                                                                                icon: 'error',
                                                                                                title: 'Error',
                                                                                                text: 'No se puede seleccionar un grupo cuando la acomodación es "Acomodación".',
                                                                                                confirmButtonText: 'Aceptar'
                                                                                            });
                                                                                            return; // Evita el cambio si la acomodación es "Acomodación"
                                                                                        }

                                                                                        if (isGrupoValid(selectedGrupo, pasajero.acomodacionPasajero)) {
                                                                                            handleGrupoAcomodacionChange(index, selectedGrupo);
                                                                                        } else {
                                                                                            Swal.fire({
                                                                                                icon: 'error',
                                                                                                title: 'Error',
                                                                                                text: 'El grupo seleccionado no cumple con los requisitos de acomodación.',
                                                                                                confirmButtonText: 'Aceptar'
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    disabled={!["SIMPLE", "DOBLE", "TRIPLE", "MULTIPLE"].includes(pasajero.acomodacionPasajero)} // Habilitar solo si acomodación es SIMPLE, DOBLE, TRIPLE o MULTIPLE
                                                                                >
                                                                                    <option value="">Grupo</option>
                                                                                    {[...Array(20).keys()].map(i => (
                                                                                        <option key={i} value={i + 1}>{i + 1}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </td>

                                                                            {/* Botón para eliminar el pasajero */}
                                                                            <td>
                                                                                {esAdminOSuperAdmin && (
                                                                                    <button
                                                                                        className="btn btn-danger"
                                                                                        onClick={() => handleEliminarDetallePasajeros(pasajero._id)}
                                                                                        title="Eliminar"
                                                                                    >
                                                                                        <i className="fa-solid fa-trash"></i>
                                                                                    </button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* --------------------------------------------------- FIN SECCION PASAJEROS -----------------------------------------------------*/}

                                                {/* --------------------------------------------------- INICIO SECCION PAGOS Y APORTES -----------------------------------------------------*/}

                                                < div className={`tab-pane fade ${key === 'pagosAportes' ? 'show active' : ''}`} id="pagosAportes">
                                                    < div className="col-md-12 border border-2 table-responsive container ">
                                                        {/* Estructura de div para dispositivos móviles */}
                                                        <div className="d-md-none">
                                                            <div className="col-12 container">
                                                                {costosPaqueteTuristico.length > 0 && (
                                                                    <div>
                                                                        {costosPaqueteTuristico
                                                                            .filter(costo => costo.anocostoPaqueteTuristico === new Date().getFullYear()) // Filtramos los costos por el año actual
                                                                            .map((costo, index) => (
                                                                                <div key={index} className="row mt-2">
                                                                                    {/* Detalle del costo */}
                                                                                    <div className="col-12">
                                                                                        <strong>Detalle del Costo:</strong> {costo.nombredelcostoPaqueteTuristico}
                                                                                    </div>
                                                                                    {/* Temporada del costo */}
                                                                                    <div className="col-12">
                                                                                        <strong>Temporada:</strong> {costo.temporadacostoPaqueteTuristico}
                                                                                    </div>
                                                                                    {/* Cantidad */}
                                                                                    <div className="col-12">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Cantidad"
                                                                                            name={`cantidad-${index}`}
                                                                                            value={cantidades[index]}
                                                                                            onChange={(e) => handleCantidadChange(e, index)}
                                                                                        />
                                                                                    </div>
                                                                                    {/* Precio */}
                                                                                    <div className="col-12">
                                                                                        <strong>Precio:</strong> {costo.preciodelcostoPaqueteTuristico != null
                                                                                            ? costo.preciodelcostoPaqueteTuristico.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                                                            : 'N/A'}
                                                                                    </div>

                                                                                    {/* Subtotal */}
                                                                                    <div className="col-12">
                                                                                        <strong>Subtotal:</strong>
                                                                                        {(cantidades[index] != null && costo.preciodelcostoPaqueteTuristico != null)
                                                                                            ? (cantidades[index] * costo.preciodelcostoPaqueteTuristico).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                                                            : '0'}
                                                                                    </div>

                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                                {/* Total del paquete */}
                                                                <div className="mt-3 mb-2 text-center">
                                                                    <div>
                                                                        <label htmlFor="" className="fw-bold fs-3 text-start">TOTAL PAQUETE</label>
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="" className="fw-bold fs-3">
                                                                            {(sumaSubtotales() && !isNaN(sumaSubtotales()))
                                                                                ? sumaSubtotales().toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                                                : '0'}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {/* Tabla para dispositivos de pantalla grande */}
                                                        <div className="d-none d-md-block">
                                                            <div className="col-md-11 table-responsive container">
                                                                {costosPaqueteTuristico.length > 0 && (
                                                                    <table className="table table-hover mt-2">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Detalle del Costo</th>
                                                                                <th>Temporada</th>
                                                                                <th>Cantidad<span className="text-danger red-asterisk">*</span></th>
                                                                                <th>Precio</th>
                                                                                <th>SubTotal</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                                            {costosPaqueteTuristico
                                                                                .filter(costo => costo.anocostoPaqueteTuristico === new Date().getFullYear()) // Filtramos los costos por el año actual
                                                                                .map((costo, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                placeholder="Nombre del costo"
                                                                                                value={costo.nombredelcostoPaqueteTuristico}
                                                                                                readOnly
                                                                                            />
                                                                                        </td>
                                                                                        <td className="col-1">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                placeholder="Temporada del costo"
                                                                                                value={costo.temporadacostoPaqueteTuristico}
                                                                                                readOnly
                                                                                            />
                                                                                        </td>

                                                                                        <td className="col-1">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control text-end"
                                                                                                placeholder="Cant"
                                                                                                name={`cantidad-${index}`}
                                                                                                value={cantidades[index]}
                                                                                                onChange={(e) => handleCantidadChange(e, index)}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="col-3">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control text-end"
                                                                                                placeholder="Precio"
                                                                                                value={costo.preciodelcostoPaqueteTuristico
                                                                                                    ? costo.preciodelcostoPaqueteTuristico.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                                                                    : '0'}
                                                                                                readOnly
                                                                                            />
                                                                                        </td>
                                                                                        <td className="col-3">
                                                                                            <input
                                                                                                name="subtotal"
                                                                                                type="text"
                                                                                                className="form-control text-end"
                                                                                                value={cantidades[index] !== undefined && cantidades[index] !== null && costo.preciodelcostoPaqueteTuristico
                                                                                                    ? (cantidades[index] * costo.preciodelcostoPaqueteTuristico).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                                                                    : '0'}
                                                                                                readOnly
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                        </tbody>


                                                                    </table>
                                                                )}
                                                                {/* Total del paquete */}
                                                                <div className="row mt-3 mb-2 ms-1 container">
                                                                    <div className="col-4 text-start">
                                                                        <label htmlFor="" className="fw-bold fs-3 text-start">TOTAL PAQUETE</label>
                                                                    </div>
                                                                    <div className="col-8 text-end">
                                                                        <label htmlFor="" className="fw-bold fs-3">
                                                                            {(sumaSubtotales() && !isNaN(sumaSubtotales()))
                                                                                ? sumaSubtotales().toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                                                : '0'}
                                                                        </label>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 mb-3">
                                                        <div className="d-fluid container">
                                                            {/* Estructura para dispositivos móviles */}
                                                            {tipoDocumento === 'RESERVA' && (
                                                                <div className="d-md-none d-fluid row mt-2 justify-content-center">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success mt-2 mb-2 w-100"
                                                                        onClick={handleAddRowPagosyAportes}
                                                                    >
                                                                        Agregar Cuota/Aporte
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {/* Estructura para dispositivos de pantalla grande */}
                                                        {tipoDocumento === 'RESERVA' && (
                                                            <div className="d-none d-md-block container">
                                                                <div className="row mt-2 justify-content-end">
                                                                    <div className="col-md-3">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success mt-2"
                                                                            onClick={handleAddRowPagosyAportes} title='Agregar Cuota/Aporte'
                                                                        >
                                                                            Agregar Cuota/Aporte
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {tipoDocumento === 'RESERVA' && (
                                                        <div className="col-md-12 border border-2 table-responsive container container-flex">
                                                            {/* Tabla para dispositivos de pantalla grande */}
                                                            <div className="d-none d-md-block">
                                                                <div className="container mt-3 mb-3 ">



                                                                    <table className="table table-hover mt-2">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Fecha del Aporte<span className="text-danger red-asterisk">*</span></th>
                                                                                <th>Medio de Pago <span className="text-danger red-asterisk">*</span></th>
                                                                                <th>Comprobante de Pago <span className="text-danger red-asterisk">*</span></th>
                                                                                <th>Valor del Aporte <span className="text-danger red-asterisk">*</span></th>
                                                                                <th scope="col">Acción</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                                            {pagosOaportes.map((detail, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>
                                                                                        <input
                                                                                            className="form-control"
                                                                                            type="date"
                                                                                            name="fechaAporte"
                                                                                            value={(detail.fechaAporte || '').slice(0, 10)}
                                                                                            onChange={(e) => handleDetalleChange(index, 'fechaAporte', e.target.value)}

                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <select
                                                                                            className="form-select"
                                                                                            name="formaPago"
                                                                                            value={detail.mediodepagoAporte || ''}
                                                                                            onChange={(e) => handleDetalleChange(index, 'mediodepagoAporte', e.target.value)}
                                                                                        >
                                                                                            <option value="">Formas de pago</option>
                                                                                            <option value="efectivo">EFECTIVO</option>
                                                                                            <option value="tarjetas">TARJETAS</option>
                                                                                            <option value="transferencia">TRANSFERENCIA</option>
                                                                                            <option value="nequi">NEQUI</option>
                                                                                            <option value="daviplata">DAVIPLATA</option>
                                                                                            <option value="cheque">CHEQUE</option>
                                                                                            <option value="consignacion">CONSIGNACIÓN</option>
                                                                                            <option value="credito">CRÉDITO</option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="file"
                                                                                            className="form-control"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            className="form-control text-end"
                                                                                            type="text"
                                                                                            name="valorAporte"
                                                                                            value={detail.valorAporte || ''}
                                                                                            onChange={(e) => handleDetalleChange(index, 'valorAporte', e.target.value)}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="d-flex">
                                                                                            {esAdminOSuperAdmin && (
                                                                                                <div className="btn-group btn-group-sm me-2" role="group">
                                                                                                    <span
                                                                                                        className="btn btn-danger"
                                                                                                        onClick={() => handleEliminarDetallePagosyAportes(detail._id)}
                                                                                                        title='Eliminar Aporte'
                                                                                                    >
                                                                                                        <i className="fa-solid fa-trash"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            )}
                                                                                            <div className="btn-group btn-group-sm" role="group">
                                                                                                <Link
                                                                                                    to={`/aplicacion/reservas/pagoaportes/${detail._id}`}
                                                                                                    className='btn btn-dark'
                                                                                                    title='Ver Recibo'
                                                                                                >
                                                                                                    <i className="fa-solid fa-file-pdf"></i>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>

                                                                    {/* Total de Aportes */}
                                                                    <div className="row mt-3 mb-2 ms-1 container">
                                                                        <div className="col-4 text-start">
                                                                            <label htmlFor="" className="fw-bold fs-3 text-start">TOTAL APORTES:</label>
                                                                        </div>
                                                                        <div className="col-8 text-end">
                                                                            <label htmlFor="" className="fw-bold fs-3">
                                                                                {isNaN(totalAportes) || totalAportes === null || totalAportes === undefined
                                                                                    ? '0'
                                                                                    : totalAportes.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 2 })}
                                                                            </label>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Estructura de div para dispositivos móviles */}
                                                            <div className="d-md-none">
                                                                <div className="d-fluid mt-3 mb-3">
                                                                    {pagosOaportes.map((detail, index) => (
                                                                        <div key={index} className='d-fluid'>
                                                                            {/* Fecha del Aporte */}
                                                                            <div className="col-12" >
                                                                                <label htmlFor="" className="fw-bold">Fecha del Aporte:</label><span className="text-danger red-asterisk">*</span>
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="date"
                                                                                    name="fechaAporte"
                                                                                    value={(detail.fechaAporte || '').slice(0, 10)}
                                                                                    onChange={(e) => handleDetalleChange(index, 'fechaAporte', e.target.value)}
                                                                                />
                                                                            </div>
                                                                            {/* Medio de Pago */}
                                                                            <div className="col-12">
                                                                                <label htmlFor="" className="fw-bold">Medio de Pago:</label><span className="text-danger red-asterisk">*</span>
                                                                                <select
                                                                                    className="form-select"
                                                                                    name="formaPago"
                                                                                    value={detail.mediodepagoAporte || ''}
                                                                                    onChange={(e) => handleDetalleChange(index, 'mediodepagoAporte', e.target.value)}
                                                                                >
                                                                                    <option value="">Formas de pago</option>
                                                                                    <option value="efectivo">EFECTIVO</option>
                                                                                    <option value="tarjetas">TARJETAS</option>
                                                                                    <option value="transferencia">TRANSFERENCIA</option>
                                                                                    <option value="nequi">NEQUI</option>
                                                                                    <option value="daviplata">DAVIPLATA</option>
                                                                                    <option value="cheque">CHEQUE</option>
                                                                                    <option value="consignacion">CONSIGNACIÓN</option>
                                                                                    <option value="credito">CRÉDITO</option>
                                                                                </select>
                                                                            </div>
                                                                            {/* Comprobante de Pago */}
                                                                            <div className="col-12">
                                                                                <label htmlFor="" className="fw-bold">Comprobante de Pago:</label><span className="text-danger red-asterisk">*</span>
                                                                                <input
                                                                                    type="file"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                            {/* Valor del Aporte */}
                                                                            <div className="col-12">
                                                                                <label htmlFor="" className="fw-bold">Valor del Aporte:</label><span className="text-danger red-asterisk">*</span>
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    name="valorAporte"
                                                                                    value={detail.valorAporte || ''}
                                                                                    onChange={(e) => handleDetalleChange(index, 'valorAporte', e.target.value)}
                                                                                />
                                                                            </div>
                                                                            {/* Acción */}
                                                                            <div>
                                                                                <>
                                                                                    {esAdminOSuperAdmin && (
                                                                                        <div className='btn-group btn-group-xl d-flex mt-3 mb-3' role="group">
                                                                                            <span
                                                                                                className="btn btn-danger d-flex align-items-center}"
                                                                                                onClick={() => handleEliminarDetallePagosyAportes(detail._id)}
                                                                                                title='Eliminar Aporte'
                                                                                            >
                                                                                                <i className="fa-solid fa-trash"></i>
                                                                                                <span className="ms-auto">Eliminar</span>
                                                                                            </span>
                                                                                        </div>


                                                                                    )}
                                                                                    <div className='btn-group btn-group-xl d-flex mt-3 mb-3' role="group">
                                                                                        <Link
                                                                                            to={`/aplicacion/reservas/pagoaportes/${detail._id}`}
                                                                                            className="btn btn-dark d-flex align-items-center"
                                                                                            title="Ver Recibo"
                                                                                        >
                                                                                            <i className="fa-solid fa-file-pdf me-auto"></i>
                                                                                            <span>Ver Pdf</span>
                                                                                        </Link>
                                                                                    </div>
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    {/* Total de Aportes */}
                                                                    <div className="mt-3 mb-2 text-center">
                                                                        <div className="col-12">
                                                                            <label htmlFor="" className="fw-bold fs-3 text-start">TOTAL APORTES:</label>
                                                                            <label htmlFor="" className="fw-bold fs-3">
                                                                                {totalAportes && !isNaN(totalAportes)
                                                                                    ? totalAportes.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 2 })
                                                                                    : '0'}
                                                                            </label>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {tipoDocumento === 'RESERVA' && (
                                                        <div className="col-md-12 border border-2 table-responsive container container-flex mt-3">
                                                            <div className="d-fluid">
                                                                {/* Estructura de div para dispositivos móviles */}
                                                                <div className="d-md-none">
                                                                    {/* Total Paquete */}
                                                                    <div className="row container-flex mt-3">
                                                                        <div className="col-12">
                                                                            <strong>Total Paquete</strong>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="text"
                                                                                name="totalpaquete"
                                                                                className="form-control text-end"
                                                                                value={calcularTotalPaquete()}
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {/* Total Aportes */}
                                                                    <div className="row container-flex">
                                                                        <div className="col-12">
                                                                            <strong>Total Aportes</strong>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="text"
                                                                                name="totalAportes"
                                                                                className="form-control text-end"
                                                                                value={isNaN(totalAportes) || totalAportes == null ? '0' : totalAportes.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 2 })}
                                                                                readOnly
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                    {/* Descuento */}
                                                                    <div className="row container-flex">
                                                                        <div className="col-12">
                                                                            <strong>Descuento</strong>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control text-end"
                                                                                name="totalDescuento"
                                                                                value={totalDescuento}
                                                                                onChange={(e) => setTotalDescuento(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {/* Total Reserva */}
                                                                    <div className="row container-flex">
                                                                        <div className="col-12">
                                                                            <strong>Total Reserva</strong>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="text"
                                                                                name="totalreserva"
                                                                                className="form-control text-end"
                                                                                value={calcularTotalReserva()}
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {/* Saldo */}
                                                                    <div className="row container-flex">
                                                                        <div className="col-12">
                                                                            <strong>Saldo</strong>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="text"
                                                                                name="saldo"
                                                                                className={`form-control text-end ${isNaN(saldo) || saldo === null || saldo === 0 ? 'bg-success border-success' : 'bg-danger border-danger'}`}
                                                                                value={isNaN(saldo) || saldo == null ? '0' : saldo.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 2 })}
                                                                                readOnly
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                    {/* NOTA */}
                                                                    <div className="row mt-3 mb-3 d-flex align-items-center justify-content-center container">
                                                                        <div className="col-12">
                                                                            <strong>NOTA:</strong>
                                                                        </div>
                                                                        <div className="col-md-12 d-flex justify-content-center align-items-center">
                                                                            <NotaDePago />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* Estructura de div para dispositivos de pantalla grande */}
                                                                <div className="row mt-4 d-none d-lg-flex">
                                                                    {/* Columna Izquierda */}
                                                                    <div className="col-6">
                                                                        {/* NOTA */}
                                                                        <div className=" align-items-center justify-content-center">
                                                                            <div className="col-md-3">
                                                                                <label className="fw-bold fs-3">NOTA:</label>
                                                                            </div>
                                                                            <div className="col-md-12 d-flex justify-content-center align-items-center">
                                                                                <NotaDePago />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* Columna Derecha */}
                                                                    <div className="col-6">
                                                                        {/* Total Paquete */}
                                                                        <div className="row mb-2">
                                                                            <div className="col-md-6 text-end">
                                                                                <strong>Total Paquete</strong>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <input
                                                                                    type="text"
                                                                                    name="totalpaquete"
                                                                                    className="form-control text-end"
                                                                                    value={calcularTotalPaquete()}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {/* Total Aportes */}
                                                                        <div className="row mb-2">
                                                                            <div className="col-md-6 text-end">
                                                                                <strong>Total Aportes</strong>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <input
                                                                                    type="text"
                                                                                    name="totalAportes"
                                                                                    className="form-control text-end"
                                                                                    value={isNaN(totalAportes) || totalAportes == null ? '0' : totalAportes.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 2 })}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {/* Descuento */}
                                                                        <div className="row mb-2">
                                                                            <div className="col-md-6 text-end">
                                                                                <strong>Descuento</strong>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control text-end"
                                                                                    name="totalDescuento"
                                                                                    value={totalDescuento}
                                                                                    onChange={(e) => setTotalDescuento(e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {/* Total Reserva */}
                                                                        <div className="row mb-2">
                                                                            <div className="col-md-6 text-end">
                                                                                <strong>Total Reserva</strong>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <input
                                                                                    type="text"
                                                                                    name="totalreserva"
                                                                                    className="form-control text-end"
                                                                                    value={calcularTotalReserva()}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {/* Saldo */}
                                                                        <div className="row mb-2">
                                                                            <div className="col-md-6 text-end">
                                                                                <strong>Saldo</strong>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <input
                                                                                    type="text"
                                                                                    name="saldo"
                                                                                    className={`form-control text-end ${isNaN(saldo) || saldo === null || saldo < 0 ? 'bg-danger border-danger' : 'bg-success border-success'}`}
                                                                                    value={isNaN(saldo) || saldo === null ? '0' : saldo.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 2 })}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                {/* --------------------------------------------------- FIN SECCION PAGOS Y APORTES -----------------------------------------------------*/}

                                            </div>
                                            <div className="modal-footer border-5 mt-5  ">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        getData(); // Carga los datos actualizados
                                                        cleanData(); // Limpia los campos del formulario
                                                        closeModal();
                                                        document.getElementById("reservasForm"); // Cierra el modal
                                                    }}
                                                    data-bs-dismiss="modal" title='Cerrar Ventana'
                                                >
                                                    Cerrar
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={actions} title='Guardar Reserva'
                                                >
                                                    Guardar Registro
                                                </button>

                                            </div>
                                            {/* Resto de tu código... */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* Fin del formulario*/}

            {/* ------------------------------------------------------------------------------------------------*/}
            {/* ------------------------------------------------------------------------------------------------*/}


            {/* Inicio de la tabla de Clientes*/}
            <div className='container container-flex card Larger shadow mt-3'>
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="dropdown no-arrow align-items-center">
                        <button className="btn btn-link btn-sm dropdown-toggle" aria-expanded="false" data-bs-toggle="dropdown" type="button">
                            <i className="fas fa-ellipsis-v text-gray-400"></i>
                        </button>
                        <div className="dropdown-menu shadow dropdown-menu-end animated--fade-in">
                            <label className="text-center dropdown-header">Exportar:</label>
                            <Link className="dropdown-item" href="#">
                                <i className="fa-solid fa-file-pdf me-2"></i>Pdf
                            </Link>
                            <Link className="dropdown-item" href="#">
                                <i className="fa-solid fa-file-excel me-2"></i> Excel
                            </Link>
                        </div>
                    </div>
                    <div>
                        <h6 className="text-primary fw-bold m-0 mt-1 text-start">Lista de Reservas</h6>
                    </div>
                    <div>
                        <button type="button" className="btn btn-primary rounded-circle aling-end ms-2" style={{ backgroundColor: "#008cba" }} onClick={() => {
                            setIsModalOpenFormReservas(true); // Abre la modal al hacer clic
                        }} title="Haga clic para agregar una nueva reserva">< i className="fa-solid fa-plus fa-beat "></i></button>
                    </div>
                </div>
                <div className='mb-2'>
                    <div className="input-group">
                        <span className="input-group-text">
                            <i className="fa-solid fa-search"></i>
                        </span>
                        <input
                            className="form-control"
                            aria-label="Search"
                            type="text"
                            placeholder="Buscar reserva..."
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>

                <div className="my-1 d-flex justify-content-end mb-3 border-5">
                    <Pagination
                        className='pagination'
                        current={page}
                        total={totalPages}
                        pageSize={1}
                        onChange={onChangePage}
                    />
                </div>

                {/* Mostrar tabla solo en dispositivos grandes (computadoras) */}
                <div className='d-none d-md-block'>
                    <div className="table-responsive">
                        <table className='table table-bordered border-1 table-hover mt-2'>
                            {/* ... contenido de la tabla ... */}
                            <thead>
                                <tr style={{ background: "#008cba", color: "#ffffff" }}>
                                    <th scope="col" className="responsive-text">#</th>
                                    <th scope="col" className="responsive-text">Documento</th>
                                    <th scope="col" className="responsive-text">Fecha Registro</th>
                                    <th scope="col" className="responsive-text">Fecha Salida</th>
                                    <th scope="col" className="responsive-text">Control</th>
                                    <th scope="col" className="responsive-text">Fecha de Regreso</th>
                                    <th scope="col" className="responsive-text">Cliente</th>
                                    <th scope="col" className="responsive-text">NPasajeros</th>
                                    <th scope="col" className="responsive-text">Destino</th>
                                    <th scope="col" className="responsive-text">Paquetes</th>
                                    {/* Mostrar la columna Alias solo si el rol es Admin o SuperAdmin */}
                                    {usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin' ? (
                                        <th scope="col" className="responsive-text">Responsable</th>
                                    ) : null}

                                    <th scope="col" className="responsive-text">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(filteredReservas) && filteredReservas.map((item, i) => (
                                    <tr key={item._id}>
                                        <td className="responsive-text"> {i + 1}</td>
                                        <td className="responsive-text" style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-start' }}>
                                                <i className={item.tipoDocumento === 'RESERVA' ? 'fa-solid fa-calendar-days' : 'fa-solid fa-hand-holding-dollar'} style={{ marginRight: '8px', fontSize: '1.2rem' }}></i>
                                                <span style={{ flex: 1, textAlign: 'center' }}>{item.tipoDocumento}</span>
                                            </div>
                                        </td>


                                        <td className="responsive-text text-nowrap overflow-auto">
                                            {item.fechaReserva ? item.fechaReserva.slice(0, 10) : 'N/A'}
                                        </td>
                                        <td className="responsive-text text-nowrap overflow-auto">
                                            {item.fechaSalida ? item.fechaSalida.slice(0, 10) : 'N/A'}
                                        </td>
                                        <td className="responsive-text">
                                            <AlertadeViaje fechaSalida={item.fechaSalida ? item.fechaSalida.slice(0, 10) : 'N/A'} />
                                        </td>
                                        <td className="responsive-text text-nowrap overflow-auto">
                                            {item.fechaLlegada ? item.fechaLlegada.slice(0, 10) : 'N/A'}
                                        </td>
                                        <td className="responsive-text">{documentoClienteMapeo[item.clientes]}</td>
                                        <td className="responsive-text"> {item.npasajeros}</td>
                                        <td className="responsive-text"> {destinoNombres[item.destinos]}</td>
                                        <td className="responsive-text"> {paqueteNombres[item.paquetesturisticos]}</td>
                                        {/* Mostrar el td solo si el rol es Admin o SuperAdmin */}
                                        {usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin' ? (
                                            <td className="responsive-text text-nowrap overflow-auto">
                                                <strong className="d-block">Responsable:</strong>{' '}
                                                {Array.isArray(item.usuarios) && item.usuarios.length > 0 ? (
                                                    item.usuarios.map(usuario => (
                                                        <span key={usuario._id} className="d-block">
                                                            {usuario.aliasUsuario}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span className="d-block">No hay usuarios asociados</span>
                                                )}
                                            </td>
                                        ) : null}


                                        <td>
                                            <div className="btn-group btn-group-sm" role="group">

                                                <span className='btn btn-primary d-flex align-items-center me-2' onClick={() => editData(item)} title='Editar Reserva'>
                                                    <i className="fa-solid fa-pencil space-i"></i>
                                                </span>

                                                {usuarios.rolUsuario === 'SuperAdmin' && (
                                                    <span className='btn btn-danger d-flex align-items-center me-2' onClick={() => deleteReserva(item._id)} title='Eliminar'>
                                                        <i className="fa-solid fa-trash"></i>
                                                    </span>
                                                )}


                                                <Link className='btn btn-dark d-flex align-items-center' to={`/aplicacion/reservas/${item._id}`} title='Ver Reserva'><i className="fa-solid fa-file-pdf"></i></Link>


                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* Mostrar tarjetas solo en dispositivos pequeños (móviles) */}
                <div className='d-md-none'>
                    {Array.isArray(reservas) && reservas.map((item, i) => (
                        <div key={item._id} className='card border-3 mt-2'>
                            {/* Contenido de la tarjeta */}
                            <div className='card-body'>
                                <h5 className='card-title'>Reserva {i + 1}</h5>
                                <p className='card-text'>
                                    <strong>Documento:</strong>{item.tipoDocumento}<br />
                                    <strong>Fecha Registro:</strong> {item.fechaReserva}<br />
                                    <strong>Fecha Salida:</strong> {item.fechaSalida ? item.fechaSalida.slice(0, 10) : 'N/A'}<br />
                                    <strong>Fecha de Regreso:</strong> {item.fechaLlegada ? item.fechaLlegada.slice(0, 10) : 'N/A'}<br />

                                    <strong>Cliente:</strong> {documentoClienteMapeo[item.clientes]}<br />
                                    <strong>NPasajeros:</strong> {item.npasajeros}<br />
                                    <strong>Destino:</strong> {destinoNombres[item.destinos]} <br />
                                    <strong>Paquete:</strong> {paqueteNombres[item.paquetesturisticos]} <br />

                                    {usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin' ? (
                                        <>
                                            <strong>Responsable:</strong>{' '}
                                            {Array.isArray(item.usuarios) && item.usuarios.length ? (
                                                item.usuarios.map(usuario => (
                                                    <span key={usuario._id} className="me-2">
                                                        {usuario.aliasUsuario}
                                                    </span>
                                                ))
                                            ) : (
                                                <span>No hay usuarios asociados</span>
                                            )}
                                            <br />
                                        </>
                                    ) : null}



                                </p>
                                <div className='btn-group btn-group-xl d-flex'>

                                    <span className='btn btn-primary d-flex align-items-center me-2 col-6' onClick={() => editData(item)} title='Editar Reserva'>
                                        <i className="fa-solid fa-pencil space-i"></i>
                                        <span className="ms-auto">Editar</span>
                                    </span>

                                    <Link className='btn btn-dark d-flex align-items-center me-2 col-6' to={`/aplicacion/reservas/${item._id}`} title='Ver Reserva'><i className="fa-solid fa-file-pdf"></i><span className="ms-auto">Ver Pdf</span></Link>

                                    {usuarios.rolUsuario === 'SuperAdmin' && (
                                        <span className='btn btn-danger d-flex align-items-center me-2 col-6' onClick={() => deleteReserva(item._id)} title='Eliminar Reserva'>
                                            <i className="fa-solid fa-trash"></i>
                                            <span className="ms-auto">Eliminar</span>
                                        </span>
                                    )}


                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="my-1 d-flex justify-content-end mb-3 border-5">
                    <Pagination
                        className='pagination'
                        current={page}
                        total={totalPages}
                        pageSize={1}
                        onChange={onChangePage}
                    />
                </div>
                {/* Fin de la tabla de Reservas*/}
            </div >
        </div >

    )
}



export default Reservas